import { AutocompleteField, IAutocompleteFieldElementType } from '@t15-ui-kit/autocompleteField';
import { Button } from '@t15-ui-kit/button';
import { DControl } from '@t15-ui-kit/dControl';
import { useMobile } from '@t15-ui-kit/media';
import { Modal } from '@t15-ui-kit/modal';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { UserModel } from 'common/models/rpc/lime';
import { useDebounce } from 'common/utils/hooks/use-debounce';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { propagateError } from 'common/utils/processing/propagate-error';
import { addSellerComposition } from 'modules/seller/views/list/compositions/add-seller.composition';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

const DEBOUNCE_MS = 500;

export const AddSellerPopup = ({ loadData }: { loadData: VoidFunction }): JSX.Element => {
  const isMobile = useMobile();
  const [optionList, setOptionList] = useState<Array<IAutocompleteFieldElementType>>([]);
  const { store, loadList } = addSellerComposition;
  const query = useRxBind(store.query$);
  const sellerList = useRxBind(store.list$);
  const user = useRxBind(store.user$);
  const debouncedQuery = useDebounce(query, DEBOUNCE_MS);

  useEffect(() => {
    return () => {
      store.query = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!debouncedQuery) {
      return;
    }

    loadList(debouncedQuery)
      .then((items: Array<IAutocompleteFieldElementType>) => {
        setOptionList(items);
      })
      .catch(setOptionList);
  }, [debouncedQuery, loadList]);

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      store.query = value;
    },
    [store]
  );

  const handleSelect = useCallback(
    (el: IAutocompleteFieldElementType) => {
      store.user = sellerList.find((item: UserModel) => item.code === el.id);
      store.query = el.title;
    },
    [store, sellerList]
  );

  const handleAddSeller = useCallback(() => {
    void addSellerComposition
      .addSeller()
      .then(loadData)
      .catch(propagateError)
      .finally(() => {
        uiKitService.closePopup();
      });
  }, [loadData]);

  return (
    <Modal
      headerText="Добавление пользователя"
      singleButton={
        <Button disabled={!user} onClick={handleAddSeller}>
          Добавить
        </Button>
      }
    >
      <DControl type="input" borderRadius="l" isMobile={isMobile} isMobileBottomOffset={false}>
        <AutocompleteField
          value={query}
          emptyMessage="Ничего не найдено"
          placeholder="Введите имя пользователя"
          onChange={handleChange}
          list={optionList}
          onSelect={handleSelect}
        />
      </DControl>
    </Modal>
  );
};
