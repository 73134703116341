import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { UserModel } from 'common/models/rpc/lime';
import { BehaviorSubject } from 'rxjs';

export class AddSellerStore implements IDestroyable {
  readonly query$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly list$: BehaviorSubject<Array<UserModel>> = new BehaviorSubject<Array<UserModel>>([]);
  readonly user$: BehaviorSubject<NotRequired<UserModel>> = new BehaviorSubject<NotRequired<UserModel>>(undefined);

  get query(): string {
    return this.query$.getValue();
  }

  set query(query: string) {
    this.query$.next(query);
  }

  get list(): Array<UserModel> {
    return this.list$.getValue();
  }

  set list(list: Array<UserModel>) {
    this.list$.next(list);
  }
  get user(): NotRequired<UserModel> {
    return this.user$.getValue();
  }

  set user(user: NotRequired<UserModel>) {
    this.user$.next(user);
  }

  destroy(): void {
    this.query$.complete();
    this.list$.complete();
    this.user$.complete();
  }
}
